<template>
  <div class="container invoices pb-16 fadeInFast">
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-row>

      <v-col cols="12" md="8">

        <HeadlineDetailView
            v-if="invoice.isQuote"
            :icon="'mdi mdi-file-document'"
            :headline="invoice.invoiceNumber + ' | '+ invoice.subject"
            :entity="'Angebot'"
        ></HeadlineDetailView>
        <HeadlineDetailView
            v-else
            :icon="'mdi mdi-file-document'"
            :headline="invoice.invoiceNumber + ' | '+ invoice.subject"
            :entity="'Rechnung'"
        ></HeadlineDetailView>
      </v-col>
      <v-col cols="12" md="4" class="text-end">
        <!--<PDFInvoice
            style="width:1300px!important"
            :invoice="invoice"
            :invoiceSettings="invoiceSettings"
            :positions="positions"
            :invoiceStatus="invoice.status"
            @generated="sureToSend = true"
            v-if="loaded"
        ></PDFInvoice>-->
        <a class="v-btn"
           v-if="invoice.status===0"
           @click="sureToSend=true">
          <v-btn class=" mt-2 ms-3 primary" width="170">
            <v-icon class="pe-2 ">
              mdi mdi-mail
            </v-icon>
            versenden
          </v-btn>
        </a>
        <a class="v-btn"
           v-if="invoice.status!==0"
           @click="$router.push('/pdf/invoice/'+invoice.id)">
          <v-btn class=" mt-2 ms-3" width="170">
            <v-icon class="pe-2 ">
              mdi mdi-download
            </v-icon>
            PDF erstellen
          </v-btn>
        </a>
        <a class="v-btn"
           v-if="invoice.status===1 && !invoice.isQuote"
           @click="sureToMarkPayed = true">
          <v-btn class=" mt-2 ms-3 primary" width="170">
            <v-icon class="pe-2 ">
              mdi mdi-cash
            </v-icon>
             Bezahlt
          </v-btn>
        </a>
        <a class="v-btn" @click="sureToStorno = true"
           v-if="invoice.status===1 ">
          <v-btn class="mt-2 ms-3 red darken-4 white--text " width="170"     >
            <v-icon class="pe-2 ">
              mdi mdi-archive-outline
            </v-icon>
            Stornieren
          </v-btn>
        </a>
        <a class="v-btn" @click="unArchiveInvoice(invoice.id)" v-if="invoice.isArchived">
          <v-btn class=" mt-2 ms-3 " width="170">
            <v-icon class="pe-2 ">
              mdi mdi-star
            </v-icon>
            Aus Archiv
          </v-btn>
        </a>
        <a class="v-btn" @click="archiveInvoice(invoice.id)" v-else>
          <v-btn class=" mt-2 ms-3 " width="170">
            <v-icon class="pe-2 ">
              mdi mdi-archive-outline
            </v-icon>
            Archivieren
          </v-btn>
        </a>
      </v-col>
    </v-row>

    <v-stepper class="mt-6 rounded-xl" flat>
      <v-stepper-header>
        <template v-for="n in invoiceStatus">
          <v-stepper-step
              :key="n.id"
              :step="n.position"
              editable
              complete
              color="red"
              edit-icon="mdi mdi-close-circle-outline"
              v-if="n.name === 'Storniert' && invoice.status ===3"
          >
            {{ n.name }}
          </v-stepper-step>
          <v-stepper-step
              :key="n.id"
              :step="n.position"
              complete
              v-else-if="invoice.status !==3 && n.name !== 'Storniert' && n.position <= invoice.status"
          >
            {{ n.name }}
          </v-stepper-step>
          <v-stepper-step
              :key="n.id"
              :step="n.position"
              v-else-if="invoice.status !==3 && n.name !== 'Storniert' && invoice.isQuote &&  n.name !== 'Bezahlt'"
          >
            {{ n.name }}
          </v-stepper-step>
          <v-stepper-step
              :key="n.id"
              :step="n.position"
              v-else-if="invoice.status !==3 && n.name !== 'Storniert' && !invoice.isQuote &&  n.name === 'Bezahlt'"
          >
            {{ n.name }}
          </v-stepper-step>
          <v-divider
              :key="n"
          ></v-divider>
        </template>
      </v-stepper-header>
    </v-stepper>

    <v-card flat class="detailCard pa-4 rounded-xl mt-6">
      <Tab
          :tabs="['Allgemein']"
          :register="tabs"
          @changed="tabs=$event"
      >
      </Tab>
      <div>
        <v-row class="pa-4">
          <v-col cols="12" lg="5">
            <KeyValueBlock
                v-if="invoice.status === 0"
                :title="''"
                :key-values="[
               {title: 'Betreff', value: invoice.subject, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'invoice',
                id: invoice.id,
                row:'subject'
              }},
                {title: 'Rechnungsnummer',
                value: invoice.invoiceNumber,
                isEdit: false,
                readonly: true,
                editBtn:{
                icon:'mdi-check-circle',
                entity: 'invoice',
                id: invoice.id,
                row:'invoiceNumber',
              }},
              {
                title: 'Status',
                value: invoice.status,
                entity: 'invoice',
                readonly: true,
                editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'invoice',
                  id: invoice.id,
                  row: 'status',
                  type: 'autoComplete',
                  autoComplete:{
                    value: invoice.status,
                    items: [{text: 'In Erstellung', value:0}, {text: 'Versendet', value:1}, {text: 'Bezahlt', value:2}, {text: 'Storniert', value:3}]
                  }
                }
              },
              {
                title: 'Kunde', value: invoice.customerName,
                btn: {
                  icon:'mdi-account',
                  entity: 'customer',
                  id: invoice.customerId
                },
                editBtn:
                {
                  icon:'mdi-check-circle',
                  entity: 'invoice',
                  id: invoice.id,
                  row: 'customerId',
                  type: 'autoComplete',
                  autoComplete:{
                    value: invoice.customerId,
                    items: customersForSelection
                  }
                }
              },
                  {title: 'Firma', value: invoice.company, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'invoice',
                id: invoice.id,
                row:'company'
              }},
                  {title: 'Name', value: invoice.name, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'invoice',
                id: invoice.id,
                row:'name'
              }},
                 {title: 'Straße und Hausnummer', value: invoice.street, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'invoice',
                id: invoice.id,
                row:'street'
              }},
                 {title: 'PLZ, Ort', value: invoice.city, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'invoice',
                id: invoice.id,
                row:'city'
              }},
                {title: 'Leistungs- / Lieferdatum', value: invoice.date, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'invoice',
                id: invoice.id,
                row:'date'
              }},
              {
                title: 'Rechnungsdatum',
                value: getStringDate(invoice.invoiceDate),
                 editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'invoice',
                  id: invoice.id,
                  row: 'invoiceDate',
                  type: 'date',
                  oldValue: getDatePicker(invoice.invoiceDate)
                }
              },
              {title: 'Header', value: invoice.header, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'invoice',
                id: invoice.id,
                row:'header'
              }},
                  {title: 'Footer', value: invoice.footer, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'invoice',
                id: invoice.id,
                row:'footer'
              }},

              ]"
                @editEntry="editEntry"
                @openEntry="openCustomer"
            ></KeyValueBlock>
            <KeyValueBlock
                v-else
                :title="''"
                :key-values="[
               {title: 'Betreff', value: invoice.subject, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'invoice',
                id: invoice.id,
                row:'subject',

              },
              readonly: true,
              },
                {title: 'Rechnungsnummer',
                value: invoice.invoiceNumber,
                isEdit: false,
                readonly: true,
                editBtn:{
                icon:'mdi-check-circle',
                entity: 'invoice',
                id: invoice.id,
                row:'invoiceNumber',
              }},
              {
                title: 'Status',
                value: invoice.status,
                entity: 'invoice',
                readonly: true,
                editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'invoice',
                  id: invoice.id,
                  row: 'status',
                  type: 'autoComplete',
                  autoComplete:{
                    value: invoice.status,
                    items: [{text: 'In Erstellung', value:0}, {text: 'Versendet', value:1}, {text: 'Bezahlt', value:2}, {text: 'Storniert', value:3}]
                  }
                }
              },
              {
                title: 'Kunde', value: invoice.customerName,
                btn: {
                  icon:'mdi-account',
                  entity: 'customer',
                  id: invoice.customerId
                },
                editBtn:
                {
                  icon:'mdi-check-circle',
                  entity: 'invoice',
                  id: invoice.id,
                  row: 'customerId',
                  type: 'autoComplete',
                  autoComplete:{
                    value: invoice.customerId,
                    items: customersForSelection
                  }
                }

              },
                  {title: 'Firma',                readonly: true,
 value: invoice.company, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'invoice',
                id: invoice.id,
                row:'company'
              }},
                  {title: 'Name',                readonly: true,
 value: invoice.name, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'invoice',
                id: invoice.id,
                row:'name'
              }},
                 {title: 'Straße und Hausnummer',                readonly: true,
 value: invoice.street, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'invoice',
                id: invoice.id,
                row:'street'
              }},
                 {title: 'PLZ, Ort',                readonly: true,
 value: invoice.city, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'invoice',
                id: invoice.id,
                row:'city'
              }},
                {title: 'Leistungs- / Lieferdatum',                readonly: true,
 value: invoice.date, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'invoice',
                id: invoice.id,
                row:'date'
              }},
              {
                title: 'Rechnungsdatum',                readonly: true,

                value: getStringDate(invoice.invoiceDate),
                 editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'invoice',
                  id: invoice.id,
                  row: 'invoiceDate',
                  type: 'date',
                  oldValue: getDatePicker(invoice.invoiceDate)
                }
              },
              {title: 'Text (oben)',                readonly: true,
 value: invoice.header, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'invoice',
                id: invoice.id,
                row:'header'
              }},
                  {title: 'Text (unten)',                readonly: true,
 value: invoice.footer, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'invoice',
                id: invoice.id,
                row:'footer'
              }},

              ]"
                @editEntry="editEntry"
                @openEntry="openCustomer"
            ></KeyValueBlock>
          </v-col>
          <v-col cols="12" lg="7" class="pt-8 pt-lg-0">
            <h3 class="pb-3">Positionen</h3>
            <v-row style="font-weight: bold">
              <v-col>
                Bezeichnung
              </v-col>
              <v-col>
                Preis
              </v-col>
              <v-col>
                Menge
              </v-col>
              <v-col>
                Einheit
              </v-col>
              <v-col>
                Steuersatz
              </v-col>
              <v-col>
                Gesamt (brutto)
              </v-col>
              <v-col>

              </v-col>
            </v-row>
            <v-row v-for="position of positions" :key="position.title">
              <v-col>
                {{ position.title }}<br v-if="position.description && position.description !==''">
                <span style="font-size: 12px" v-if="position.description && position.description !==''">{{position.description}}</span>
              </v-col>
              <v-col>
                {{ replaceDot(position.price) }}€
              </v-col>
              <v-col>
                {{ position.quant }}
              </v-col>
              <v-col>
                {{ position.einheit }}
              </v-col>
              <v-col>
                {{ position.steuersatz }}%
              </v-col>
              <v-col>
                {{ replaceDot(position.sum) }}€
              </v-col>
              <v-col>
                <v-icon v-if="invoice.status ===0" @click="deletePosition(position.id)">mdi-delete</v-icon>
              </v-col>
            </v-row>

            <v-btn v-if="invoice.status ===0" @click="positionDialog=true" class="primary  mt-5">Neue Postition</v-btn>

            <h2 class="mt-6 mb-2" v-if="invoice.status === 0">Vorschau</h2>
            <v-card class="pa-6 pb-16 rounded-xl" v-if="invoice.status === 0" style="position:relative">
              <PDFInvoice
                  style="position:relative"
                  :invoice="invoice"
                  :invoiceSettings="invoiceSettings"
                  :positions="positions"
                  :print="false"
              >
              </PDFInvoice>
            </v-card>
          </v-col>
        </v-row>

      </div>
    </v-card>
    <CreatePosition
        :dialog="positionDialog"
        :invoiceId="$route.params.id"
        @hideDialog="positionDialog=false"
    >
    </CreatePosition>
    <SureToDelete
        :dialog="sureToSend"
        :buttonText="'Versenden'"
        buttonColor="primary"
        :text="'Bist du sicher, dass du das Dokument versenden möchtest? Du kannst es anschließend nicht mehr bearbeiten.'"
        @cancel="sureToSend = false"
        @sure="versenden(); sureToSend = false"
    ></SureToDelete>
    <SureToDelete
        :dialog="sureToMarkPayed"
        buttonColor="primary"
        :buttonText="'Als bezahlt markieren'"
        :text="'Bist du sicher, dass du das Dokument als bezahlt markieren möchtest?'"
        @cancel="sureToMarkPayed = false"
        @sure="markAsPayed(); sureToMarkPayed = false"
    ></SureToDelete>
    <SureToDelete
        :dialog="sureToStorno"
        :buttonText="'Stornieren'"
        :text="'Bist du sicher, dass du das Dokument stornieren möchtest möchtest?'"
        @cancel="sureToStorno = false"
        @sure="storno(); sureToStorno = false"
    ></SureToDelete>
  </div>

</template>
<script>

import {mapGetters} from "vuex"
import {error, dotToKomma, msgObj, date2String, getDateForPicker} from "@/helper/helper";
const SureToDelete = () => import("@/components/generalUI/SureToDelete");
const Message = () => import("@/components/generalUI/Message");
const KeyValueBlock = () => import("@/components/detailView/KeyValueBlock");
const HeadlineDetailView = () => import("@/components/detailView/HeadlineDetailView");
const Tab = () => import("@/components/generalUI/Tab");
const CreatePosition = () => import("@/components/dialog/createDialogs/CreatePosition");
const PDFInvoice = () => import("@/components/PdfExport/Invoice");

export default {
  name: 'invoiceDetail',
  components: {
    Message,
    HeadlineDetailView,
    KeyValueBlock,
    CreatePosition,
    Tab,
    PDFInvoice,
    SureToDelete
  },
  mounted() {
    this.$store.dispatch('auth/checkLogin', {}).then((res) => {
      this.$store.dispatch('invoice/getInvoice', {
        uid: res,
        invoiceId: this.$route.params.id
      }).then(() => {
        this.$store.dispatch('settings/getInvoiceSettings', {
          uid: res,
        }).then(()=>{
          this.$store.dispatch('invoice/getPositionsForInvoice', {
            invoiceId: this.$route.params.id,
          }).then(()=>{
            this.loaded = true
            this.$store.dispatch('customer/getCustomersForSelection', {
              uid: res,
            }).catch((err) => {
              this.message = error(err)
            })
          }).catch((err) => {
            this.message = error(err)
          })
        }).catch((err) => {
          this.message = error(err)
        })

      }).catch((err) => {
        this.message = error(err)
      })
    })

  },
  computed: {
    ...mapGetters('invoice', {
      invoice: 'invoice',
      positions: 'positions',
    }),
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('customer', {
      customersForSelection: 'customersForSelection',
    }),
    ...mapGetters('settings', {
      invoiceSettings: 'invoiceSettings',
    }),
  },
  data() {
    return {
      positionDialog: false,
      message: msgObj(),
      tabs: 0,
      editId: "",
      loaded:false,
      invoiceStatus: this.getS(),
      sureToSend: false,
      sureToMarkPayed: false,
      sureToStorno: false
    }
  },
  methods: {
    getS(){

      return [
        {name: 'In Erstellung', position: 0},
        {name: 'Versendet', position: 1},
        {name: 'Bezahlt', position: 2},
        {name: 'Storniert', position: 3},
      ]
    },
    getDatePicker(time) {
      return getDateForPicker(time)
    },
    replaceDot(float) {
      return dotToKomma(float)
    },
    getStringDate(date) {
      return date2String(date)
    },
    deletePosition(id) {
      this.$store.dispatch('invoice/deletePosition', {
        invoiceId: this.$route.params.id,
        positionId: id,
      }).catch((err) => {
        this.message = error(err)
      })
    },
    versenden(){
      this.$store.dispatch('invoice/setInvoiceNumber', {
        invoiceId: this.$route.params.id,
        uid: this.user.id,
        isQuote: this.invoice.isQuote
      }).then(()=>{
        this.editEntry({
          id: this.$route.params.id,
          newValue: this.getSumFromPositions(),
          row: 'price'
        })
        this.editEntry({
          id: this.$route.params.id,
          newValue: 1,
          row: 'status'
        })
      }).catch(() => {
        this.message = error('Bitte überprüfe zunächst deine Rechnungseinstellungen.')
      })
    },
    storno(){
      this.editEntry({
        id: this.$route.params.id,
        newValue: 3,
        row: 'status'
      })
    },
    markAsPayed(){
      this.editEntry({
        id: this.$route.params.id,
        newValue: 2,
        row: 'status'
      })
    },
    archiveInvoice(id) {
      this.$store.dispatch('customer/editField', {
        entity: "invoice",
        uid: this.user.id,
        id: id,
        newValue: true,
        row: 'isArchived'
      }).then(() => {
        this.$router.push('/rechnungen')
      }).catch((err) => {
        this.message = error(err)
      })
    },
    unArchiveInvoice(id) {
      this.$store.dispatch('customer/editField', {
        entity: "invoice",
        uid: this.user.id,
        id: id,
        newValue: false,
        row: 'isArchived'
      }).then(() => {
        this.$router.push('/rechnungen')
      })
    },

    editEntry(payload) {
      switch (payload.row) {
        case "customerId":
          this.$store.dispatch('customer/getCustomer', {
            uid: this.user.id,
            customerId: payload.newValue,
          }).then(() => {
            this.$store.dispatch('customer/editAutoCompleteCustomer', {
              uid: this.user.id,
              id: payload.id,
              newValue: payload.newValue,
            })
          }).catch((err) => {
            this.message = error(err)
          })
          break
      }
      if(payload.row ==='invoiceDate'){
        payload.newValue = (new Date(payload.newValue)).getTime()
      }
      this.$store.dispatch('customer/editField', {
        entity: "invoice",
        uid: this.user.id,
        id: payload.id,
        newValue: payload.newValue,
        row: payload.row
      }).then(() => {
        this.$store.dispatch('invoice/getInvoice', {invoiceId: payload.id, uid: this.user.id})
            .catch((err) => {
              this.message = error(err)
            })
      }).catch((err) => {
        this.message = error(err)
      })
    },
    goTo(link) {
      this.$router.push(link)
    },
    openCustomer(payload) {
      this.$router.push('/kunden/kunde/' + payload.id)
    },
    getSumFromPositions() {
      let sum = 0
      for (let position of this.positions) {
        sum = sum + position.sum
      }
      return sum
    },
    getSumFromPositionsNetto() {
      let sum = 0
      for (let position of this.positions) {
        sum = sum + position.price * position.quant
      }
      return sum
    }
  }
}
</script>
